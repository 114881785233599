'use client';

import { LocalStorage } from '@mentimeter/storage';
import { type ReactNode } from 'react';
import {
  getVisitorToken,
  VISITOR_LOCAL_STORAGE_KEY,
} from '@mentimeter/splitio';

export function VisitorTokenLocalStorage({
  children,
  newlyGeneratedServerToken,
}: {
  children: ReactNode;
  newlyGeneratedServerToken: string | null;
}) {
  // we want to ensure that we have visitor tokens for all visitors coming to the site from external sources
  // such as emails or buttons in voting web
  const localStorageVisitorToken = getVisitorToken();
  if (!localStorageVisitorToken && newlyGeneratedServerToken) {
    LocalStorage.setItem({
      key: VISITOR_LOCAL_STORAGE_KEY,
      value: newlyGeneratedServerToken,
      type: 'functional',
    });
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
